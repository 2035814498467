import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import classes from "./Sidebar.module.css";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as CaseEntryIcon } from "../../../assets/icons/case-entry.svg";
import { ReactComponent as CompletedCasesIcon } from "../../../assets/icons/completed-cases.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import CircledButton from "./../CircledButton/index";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow-up.svg";
import { imageUrl } from "../../../config";
import AuthImage from "../AuthImage";
const Sidebar = ({ isCollapsed, toggleSidebar, user }) => {
  const location = useLocation();

  const menuItems = [
    { icon: <DashboardIcon />, text: "Dashboard", path: "/dashboard" },
    { icon: <CaseEntryIcon />, text: "Case Entry", path: "/case-entry" },
    {
      icon: <CompletedCasesIcon />,
      text: "Library",
      path: "/completed-cases",
    },
  ];

  const userActions = [
    { icon: <SettingsIcon />, text: "Settings", path: "/settings" },
    { icon: <LogoutIcon />, text: "Logout", path: "/logout" },
  ];

  const trimName = (name) => {
    return name.length > 15 ? name.substring(0, 15) : name;
  };

  return (
    <div
      className={`${classes.sidebar} ${isCollapsed ? classes.collapsed : ""}`}
    >
      <nav className={classes.sidebarNav}>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                className={clsx(
                  classes.navLink,
                  location.pathname === item.path && classes.active
                )}
              >
                <span className={classes.icon}>{item.icon}</span>
                {!isCollapsed && (
                  <>
                    <span className={classes.text}>{item.text}</span>
                    <CircledButton
                      icon={ArrowUpIcon}
                      ariaLabel={`Goto ${item.text}`}
                      className={classes.linkButton}
                    />
                  </>
                )}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={classes.userActions}>
          <hr className={classes.divider} />
          {!isCollapsed && (
            <li className={classes.userRole}>{!isCollapsed && "ADMIN"}</li>
          )}
          <li
            className={classes.userInfo}
            style={{
              backgroundColor: user?.profileImage
                ? "transparent"
                : "var(--background-color-secondary)",
            }}
          >
            <div className={classes.userAvatar}>
              {user?.profileImage ? (
                <AuthImage
                  src={imageUrl(user?.profileImage)}
                  alt={`${user?.firstName?.[0]} ${user?.lastName?.[0]}`}
                  className={classes.profileImage}
                />
              ) : (
                <span>
                  {user?.firstName?.[0]}
                  {user?.lastName?.[0]}
                </span>
              )}
            </div>
            {!isCollapsed && (
              <span className={classes.userName}>
                {trimName(`${user?.firstName} ${user?.lastName}`)}
              </span>
            )}
          </li>

          {userActions.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                className={clsx(
                  classes.navLink,
                  location.pathname === item.path && classes.active
                )}
              >
                <span className={classes.icon}>{item.icon}</span>
                {!isCollapsed && (
                  <>
                    <span className={classes.text}>{item.text}</span>
                    <CircledButton
                      icon={ArrowUpIcon}
                      ariaLabel={`Goto ${item.text}`}
                      className={classes.linkButton}
                    />
                  </>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
