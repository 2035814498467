import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Image } from "primereact/image";
import debounce from "lodash.debounce";

const imageCache = {};

const AuthImage = ({ src, alt, className, fallbackSrc, isPreview = false }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const { accessToken } = useSelector((state) => state.auth);
  const fetchImage = useRef(
    debounce((src) => {
      if (imageCache[src]) {
        setImgSrc(imageCache[src]);
        return;
      }

      fetch(src, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          imageCache[src] = objectUrl;
          setImgSrc(objectUrl);
        })
        .catch(() => {
          setImgSrc(fallbackSrc);
        });
    }, 300)
  ).current;

  useEffect(() => {
    if (!src) {
      setImgSrc(fallbackSrc);
      return;
    }

    fetchImage(src);

    return () => {
      if (imgSrc) {
        URL.revokeObjectURL(imgSrc);
      }
    };
  }, [src, fallbackSrc, fetchImage]);

  if (isPreview) {
    return <ImagePreview src={imgSrc || fallbackSrc} alt={alt} />;
  }
  return <img src={imgSrc || fallbackSrc} alt={alt} className={className} />;
};

// Optimize image grid to handle large images more efficiently
const ImagePreview = React.memo(({ src, alt }) => {
  return (
    <Image
      src={src}
      alt={alt}
      preview
      width="250"
      loading="lazy" // Add lazy loading
    />
  );
});

export default AuthImage;
